import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Multimedia/Audio/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/MultimediaPerm';
import AudioTable from 'components/Web_User_Interface/1440p_Series/Multimedia/Audio/audioTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Multimedia Menu // Audio Settings",
  "path": "/Web_User_Interface/1440p_Series/Multimedia/Audio/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Here you can adjust the amplification of the cameras microphones/audio-in as well as the volume of the audio-out signal.",
  "image": "./WebUI_1440p_SearchThumb_Multimedia_Audio.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Multimedia_Audio.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Multimedia Menu // Audio Settings' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Here you can adjust the amplification of the cameras microphones/audio-in as well as the volume of the audio-out signal.' image='/images/Search/WebUI_1440p_SearchThumb_Multimedia_Audio.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Multimedia_Audio.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Multimedia/Audio/' locationFR='/fr/Web_User_Interface/1440p_Series/Multimedia/Audio/' crumbLabel="Audio Settings" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "multimedia-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#multimedia-menu",
        "aria-label": "multimedia menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Multimedia Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a7ca737baabbc3b97dbde0f86e378bf3/aa61c/1440p_Settings_Multimedia_Audio.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB5ElEQVQoz3XRS2sTURjG8XyQbIJNzGXaRbSQyyRzyaSkzSQzc87MJJmYWFNbpYKIFwq2FXXTjaCgoKBSBEFXCvWyrd/sLzMlJhsXD4ezOD/e5z2ptbU1FEVBWV3l8kqG9uw5wck5zpMzusc/6Rz+YHMp8b17dMb0xR+aowP0Ro1iSSGdTpPJZEgVi0XilEolsiuXsPpDRvtH+DsPkf/L7AGDO8/oiOvUKnUqFZX1qxXK5XVSMfQPzOZoGRpRKJGujdffWkoX37XxpYf0ffzhGLtrY2gmutZC19rUqk1ShUKB+ZTZbBbLsojG1xBS4gmJEIvTj6EwQg4nyGiGlC6bGxpa06ShmlQr6gU4nzKXy9FqtYiiiCAIEkAIged5yZmAwQB/EKM3GI76OHYTtW6gaya1amMBxpmD4/E4eRyjMZTEdQiEm1R2R1Oc6W0Cv0en1cDUdSxDp1ZVF5WXwTAcIKVcmtBFDLfxJvuEwYRbdo+ZN2Jn4GG39WTvhm5QrdQXnzKvbJpmArmuS99xcTyB8PrI3ceIR2/ZvXnA6y2bk67DvZHPtreBqmpJ5WSH87oxmM/nUZQS5fIVTMMi7HU43Av49e4p598/8e3zKV/fv+L0zUt+f/nIh+O73I8sDMNAa1yAfwGh7VVlUZfH7QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a7ca737baabbc3b97dbde0f86e378bf3/e4706/1440p_Settings_Multimedia_Audio.avif 230w", "/en/static/a7ca737baabbc3b97dbde0f86e378bf3/d1af7/1440p_Settings_Multimedia_Audio.avif 460w", "/en/static/a7ca737baabbc3b97dbde0f86e378bf3/7f308/1440p_Settings_Multimedia_Audio.avif 920w", "/en/static/a7ca737baabbc3b97dbde0f86e378bf3/2cb71/1440p_Settings_Multimedia_Audio.avif 1065w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a7ca737baabbc3b97dbde0f86e378bf3/a0b58/1440p_Settings_Multimedia_Audio.webp 230w", "/en/static/a7ca737baabbc3b97dbde0f86e378bf3/bc10c/1440p_Settings_Multimedia_Audio.webp 460w", "/en/static/a7ca737baabbc3b97dbde0f86e378bf3/966d8/1440p_Settings_Multimedia_Audio.webp 920w", "/en/static/a7ca737baabbc3b97dbde0f86e378bf3/fa9eb/1440p_Settings_Multimedia_Audio.webp 1065w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a7ca737baabbc3b97dbde0f86e378bf3/81c8e/1440p_Settings_Multimedia_Audio.png 230w", "/en/static/a7ca737baabbc3b97dbde0f86e378bf3/08a84/1440p_Settings_Multimedia_Audio.png 460w", "/en/static/a7ca737baabbc3b97dbde0f86e378bf3/c0255/1440p_Settings_Multimedia_Audio.png 920w", "/en/static/a7ca737baabbc3b97dbde0f86e378bf3/aa61c/1440p_Settings_Multimedia_Audio.png 1065w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a7ca737baabbc3b97dbde0f86e378bf3/c0255/1440p_Settings_Multimedia_Audio.png",
              "alt": "Web User Interface - 1440p Series - Multimedia Audio",
              "title": "Web User Interface - 1440p Series - Multimedia Audio",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <AudioTable mdxType="AudioTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      